/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import MenuBook from '@material-ui/icons/MenuBook';
import Assignment from '@material-ui/icons/Assignment';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Link,
  Sidebar,
  sidebarConfig,
  SidebarItem,
  SidebarSubmenu,
  SidebarSubmenuItem,
  SidebarDivider,
  SidebarSpace,
  SidebarScrollWrapper,
  SidebarGroup,
  SidebarPage,
  useSidebarOpenState,
} from '@backstage/core-components';
import { useApp } from '@backstage/core-plugin-api';
// import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CategoryIcon from '@material-ui/icons/Category';
import LayersIcon from '@material-ui/icons/Layers';
import Edit from '@material-ui/icons/Edit';
import Score from '@material-ui/icons/Score';
import LiveHelp from '@material-ui/icons/LiveHelpOutlined';
import BuildIcon from '@material-ui/icons/Build';
import { InfraWalletIcon } from '@electrolux-oss/plugin-infrawallet';
import { TestkubeIcon } from '@internal/backstage-plugin-testkube'

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      {/* Global nav, not org-specific */}
      <SidebarItem icon={HomeIcon} to="/" text="Home" />
      <SidebarItem icon={LayersIcon} to="explore" text="Explore" />
      <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog">
        <SidebarSubmenu title="Catalog">
          <SidebarSubmenuItem
            title="Domains"
            to="catalog?filters[kind]=domain"
            icon={useApp().getSystemIcon('kind:domain')}
          />
          <SidebarSubmenuItem
            title="Systems"
            to="catalog?filters[kind]=system"
            icon={useApp().getSystemIcon('kind:system')}
          />
          <SidebarSubmenuItem
            title="Components"
            to="catalog?filters[kind]=component"
            icon={useApp().getSystemIcon('kind:component')}
          />
          <SidebarSubmenuItem
            title="APIs"
            to="catalog?filters[kind]=api"
            icon={useApp().getSystemIcon('kind:api')}
          />
          <SidebarDivider />
          <SidebarSubmenuItem
            title="Resources"
            to="catalog?filters[kind]=resource"
            icon={useApp().getSystemIcon('kind:resource')}
          />
          <SidebarDivider />
          <SidebarSubmenuItem
            title="Groups"
            to="catalog?filters[kind]=group"
            icon={useApp().getSystemIcon('kind:group')}
          />
          <SidebarSubmenuItem
            title="Users"
            to="catalog?filters[kind]=user"
            icon={useApp().getSystemIcon('kind:user')}
          />
        </SidebarSubmenu>
      </SidebarItem>
      <SidebarItem icon={ExtensionIcon} text="APIs">
        <SidebarSubmenu title="APIs">
          <SidebarSubmenuItem title="APIs" to="api-docs" icon={ExtensionIcon} />
          <SidebarSubmenuItem
            title="API-Studio"
            to="https://api-studio.alsenseplatform.com/dashboard"
            icon={Edit}
          />
          <SidebarSubmenuItem
            title="API-Guidelines"
            to="https://backstage.alsenseplatform.com/catalog/default/domain/Alsense/docs/RESTAPIGuideline/"
            icon={Assignment}
          />
        </SidebarSubmenu>
      </SidebarItem>
      <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
      <SidebarItem icon={CreateComponentIcon} to="create" text="Create" />
      {/* End global nav */}
      <SidebarDivider />
      <SidebarScrollWrapper>
        <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
      </SidebarScrollWrapper>
      <SidebarItem icon={Score} to="score-board" text="Tech-Insights" />
      <SidebarItem icon={LiveHelp} to="qeta" text="Q&A" />
      <SidebarItem icon={BuildIcon} to="toolbox" text="ToolBox" />
      <SidebarItem icon={InfraWalletIcon} to="infrawallet" text="InfraWallet" />
      <SidebarItem icon={TestkubeIcon} to="testkube" text="Testkube" />
      <SidebarDivider />
      <SidebarItem icon={MenuBook} to="datacatalog" text="Data-Catalog" />
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
